import { Popover } from 'antd';
import { TooltipIcon } from '../../routes/customer/itemMangement/newItemDetail/@svg/TooltipIcon/TooltipIcon';
import './index.scss';

const RowDetail = ({
  label,
  value,
  isLink = false,
  href = null,
  underline = false,
  action = null,
  hasTooltip = false,
  nameRow = false,
  childrenPopover = <></>,
  rows = 1,
}) => {
  return (
    <div className="info-item">
      <div className={`info-item__left`}>
        <div className="label">
          {label}
          {hasTooltip ? (
            <Popover
              trigger="click"
              overlayClassName="custom-tooltip"
              placement="bottom"
              arrow={true}
              content={childrenPopover}
            >
              <div className="icon">
                <TooltipIcon />
              </div>
            </Popover>
          ) : (
            ''
          )}
        </div>
        <span>:</span>
      </div>
      <div className={`info-item__right ${underline ? 'underline' : ''} ${nameRow ? `text-${rows}-line` : ''}`}>
        {isLink ? (
          <a href={href} target="_blank" className={`text-${rows}-line`}>
            {value}
          </a>
        ) : (
          value
        )}
      </div>
      {action && <div className="info-item__editTracking">{action}</div>}
    </div>
  );
};

export default RowDetail;
