import { useForm } from 'antd/es/form/Form';
import { getLinkUserEbayNavigate } from '../../../../util/function-util';
import { AxiosResponse } from 'axios';
import { OrderXML, ShippingAddress } from '../../../../apis/client-axios';
import RowDetail from '../../../../components/InfoRow/NewRowDetail';
import { SvgPencil } from '../../../../components/@svg/SvgPencil';
import { TDataRequest } from '../request';

type TProps = {
  orderEbay: AxiosResponse<OrderXML, any>;
  modalEditAddress: {
    isOpen: boolean;
    isChange: boolean;
    data: Omit<ShippingAddress, 'AddressID' | 'AddressOwner' | 'CountryName'>;
  };
  setModalEditAddress: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      isChange: boolean;
      data: Omit<ShippingAddress, 'AddressID' | 'AddressOwner' | 'CountryName'>;
    }>
  >;
  dataRequest: TDataRequest;
};

const EditDetailBuyer = ({ orderEbay, modalEditAddress, setModalEditAddress, dataRequest }: TProps) => {
  const [formEditAddress] = useForm<typeof modalEditAddress.data>();

  const mapAddress = (ShippingAddress: typeof modalEditAddress.data) => {
    const addressLine1 = ShippingAddress?.Street1 || '';
    const addressLine2 = ShippingAddress?.Street2 || '';
    const city = ShippingAddress?.CityName || '';
    const countryCode = ShippingAddress?.Country || '';
    const postalCode = ShippingAddress?.PostalCode || '';
    const stateOrProvince = ShippingAddress?.StateOrProvince || '';

    return `${addressLine1}, ${addressLine2 ? `${addressLine2},` : ''} ${city}, ${stateOrProvince} ${postalCode}, ${countryCode}`;
  };
  return (
    <>
      {orderEbay?.data?.BuyerUserID && (
        <div className="box address">
          <div className="title">
            <h3>バイヤー情報</h3>
          </div>
          <div className={`list-info list-info__address ${dataRequest.confirm ? 'address-confirm' : ''}`}>
            {!dataRequest.confirm && (
              <RowDetail
                label="Order no."
                value={orderEbay?.data?.OrderID}
                isLink
                underline
                href={''}
                action={
                  <span
                    onClick={() => {
                      formEditAddress.setFieldsValue(modalEditAddress.data);
                      setModalEditAddress({ ...modalEditAddress, isOpen: true });
                    }}
                  >
                    <SvgPencil />
                  </span>
                }
              />
            )}
            <RowDetail
              label="バイヤーID"
              value={orderEbay?.data?.BuyerUserID}
              isLink
              underline
              href={getLinkUserEbayNavigate(orderEbay?.data?.BuyerUserID, 'usr')}
            />
            <RowDetail label="名前" value={modalEditAddress.data.Name} />
            <RowDetail label="住所" value={mapAddress(modalEditAddress.data)} />
            <RowDetail label="電話番号" value={modalEditAddress.data.Phone} />
          </div>
        </div>
      )}
    </>
  );
};

export default EditDetailBuyer;
