import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SvgIncrease from '../../components/@svg/SvgIncrease';
import SvgReduction from '../../components/@svg/SvgReduction';
import { USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../constants/router';
import './SidebarLogo.scss';

type MenuItem = Required<MenuProps>['items'][number];

export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  permission?: string[],
  isActive = false,
): MenuItem => {
  return {
    key,
    // icon,
    children,
    label,
    type,
    permission,
    isActive,
  } as MenuItem;
};

interface ISideBarContentProp {
  menuItems?: MenuProps['items'];
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const SidebarContent = (props: ISideBarContentProp) => {
  const { collapsed, setCollapsed } = props;
  const location = useLocation();
  const navigate = useNavigate();
  let pathName =
    location.pathname === USER_ROUTE_PATH.SHIP_BUYER_REQUESTED
      ? '/' + USER_ROUTE_NAME.RETURN_MANAGEMENT
      : location.pathname;
  const [current, setCurrent] = useState<string>(pathName);

  const selectedKeys = pathName.substring(1);
  const selected = selectedKeys.split('/')[0];

  const defaultOpenKeys = selectedKeys.split('/')[1];

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
      window.open(e.key, '_blank');
    } else {
      setCurrent(e.key);
      navigate(e.key, { state: e.key === USER_ROUTE_NAME.RETURN_MANAGEMENT ? { page: 'LIST' } : undefined });
    }
  };

  return (
    <>
      <div
        className="slider d-flex flex-column justify-content-between"
        style={{ height: '100%', position: 'relative' }}
      >
        <Menu
          className="ant-custom-menu"
          onClick={onClick}
          defaultActiveFirst
          selectedKeys={[selected || selectedKeys || current]}
          defaultOpenKeys={[defaultOpenKeys]}
          mode="inline"
          items={props.menuItems}
        />
        {/* {!collapsed && (
          <div className="text-bottom">
            <div className="line"></div>
            <div className="p-l-r-14">
              <div className="top">
                <div className="text-nowrap">
                  <a className="a-normal" target={'_blank'} href={USER_ROUTE_NAME.TERMS_OF_SERVICE}>
                    利用規約
                  </a>
                </div>
                <div className="line-along">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path d="M1 1V15" stroke="#F2F2F2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="text-nowrap">
                  <a className="a-normal" target={'_blank'} href={USER_ROUTE_NAME.PRIVACY_POLICY}>
                    プライバシーポリシー
                  </a>
                </div>
              </div>
              <div className="m-t-4">
                <a className="a-normal" target={'_blank'} href={USER_ROUTE_NAME.SPECIFIED_TRANSACTION_ART}>
                  特定商取引法に基づく表記
                </a>
              </div>
            </div>
          </div>
        )} */}
        <div className="m-b-9 text-center collapse-button">
          <Button type="text" onClick={() => setCollapsed(!collapsed)}>
            {!collapsed ? (
              <>
                <SvgReduction />
                {/* <span className="text-white m-l-8 font-size-12">縮小</span> */}
              </>
            ) : (
              <>
                <SvgIncrease />
                {/* <span className="text-white">拡大</span> */}
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SidebarContent;
