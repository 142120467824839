import { Input, InputProps } from 'antd';
import { useIntl } from 'react-intl';
import './style.scss';
import EyeInvisibleOutlined from '../@svg/EyeInvisibleOutlined';
import EyeOutlined from '../@svg/EyeOutlined';

interface CustomInputProps extends InputProps {
  placeholder?: string;
  className?: string;
  isPassword?: boolean;
  width?: number;
  height?: number;
}

const CustomInput = (props: CustomInputProps) => {
  const { placeholder, className, isPassword, width, height, ...restProps } = props;

  const inputStyle = { height, width };

  return !isPassword ? (
    <Input
      style={inputStyle}
      maxLength={255}
      placeholder={placeholder || undefined}
      className={`ant-custom-input ${className}`}
      {...restProps}
    />
  ) : (
    <Input.Password
      style={inputStyle}
      maxLength={255}
      placeholder={placeholder || undefined}
      className={`ant-custom-input ${className}`}
      {...restProps}
      autoComplete="new-password"
      iconRender={(visible) =>
        visible ? (
          <span style={{ width: '24px', height: '24px' }}>
            <EyeInvisibleOutlined />
          </span>
        ) : (
          <span style={{ width: '24px', height: '24px' }}>
            <EyeOutlined />
          </span>
        )
      }
    />
  );
};

CustomInput.TextArea = Input.TextArea;
export default CustomInput;
