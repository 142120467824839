export const BackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} viewBox="0 0 16 14" fill="none">
      <path
        d="M2.06116 6.37109H14.6613C14.8284 6.37109 14.9886 6.43747 15.1068 6.55562C15.2249 6.67377 15.2913 6.83401 15.2913 7.0011C15.2913 7.16819 15.2249 7.32843 15.1068 7.44658C14.9886 7.56473 14.8284 7.6311 14.6613 7.6311H2.06116C1.89407 7.6311 1.73383 7.56473 1.61568 7.44658C1.49753 7.32843 1.43115 7.16819 1.43115 7.0011C1.43115 6.83401 1.49753 6.67377 1.61568 6.55562C1.73383 6.43747 1.89407 6.37109 2.06116 6.37109Z"
        fill="#514B4D"
      />
      <path
        d="M2.32225 7.00105L7.54752 12.2251C7.66582 12.3434 7.73228 12.5038 7.73228 12.6711C7.73228 12.8384 7.66582 12.9988 7.54752 13.1171C7.42922 13.2354 7.26877 13.3019 7.10147 13.3019C6.93418 13.3019 6.77373 13.2354 6.65543 13.1171L0.985381 7.44709C0.926711 7.38857 0.880162 7.31905 0.848402 7.24251C0.816641 7.16597 0.800293 7.08392 0.800293 7.00105C0.800293 6.91818 0.816641 6.83613 0.848402 6.75959C0.880162 6.68305 0.926711 6.61353 0.985381 6.555L6.65543 0.884953C6.77373 0.766654 6.93418 0.700195 7.10147 0.700195C7.26877 0.700195 7.42922 0.766654 7.54752 0.884953C7.66582 1.00325 7.73228 1.1637 7.73228 1.331C7.73228 1.4983 7.66582 1.65874 7.54752 1.77704L2.32225 7.00105Z"
        fill="#514B4D"
      />
    </svg>
  );
};
