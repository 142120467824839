const USER = '';

export const ACTION = {
  CREATE: 'create',
  DETAIL: 'detail',
  REQUEST: 'request',
  NEW_DETAIL: 'new-detail',
};

export const USER_ROUTE_NAME = {
  HOME: '',
  DASHBOARD: '',
  PROFILE: 'profile',

  AUTH: 'auth',
  SIGNIN: 'signin',
  SIGNUP: 'signup',

  FORGOT: 'forgot',
  RESET_PASSWORD: 'reset-password',
  OTP_INPUT: 'otp-input',
  SUCCESS: 'success',
  FAIL: 'fail',

  EDIT_CARD: 'edit_card',
  CARD_INPUT: 'card_input',

  RETURN_MANAGEMENT: 'return-management',
  RETURN_STORAGE: 'return-storage',
  RETURN_ITEM: 'return-item',
  RETURN_ITEM_DETAIL: `${ACTION.DETAIL}/:id`,
  RETURN_ITEM_NEW_DETAIL: `${ACTION.NEW_DETAIL}/:id`,

  CHAT_SUPPORT: 'chat-support',

  VERIFY: 'verify-subuser',

  PLAN_SETTING: 'plan-setting',
  SELECT_PLAN: 'select-plan-setting',
  DETAIL_PLAN: 'detail-plan',

  MEMBER_MANAGEMENT: 'member-management',
  SYSTEM_NOTIFICATION: 'system-notification',
  SETTING_MANAGEMENT: 'setting',

  QUIT_MEMBERSHIP: 'quit-membership',

  EBAY_MANAGEMENT: 'ebay-management',

  PAYMENT_LIST_HISTORY: 'payment-list-history',

  BIZ_NOTIFICATION: 'biz-notification',
  SHIP_BUYER_REQUEST: 'ship-buyer-request',
  SUPPORT: 'support',

  NOTIFY_SETTING: 'setting-notification',

  CREATE: `${ACTION.CREATE}`,
  REQUEST: `${ACTION.REQUEST}`,
  DETAIL: `${ACTION.DETAIL}/:id`,
  NEW_DETAIL: `${ACTION.NEW_DETAIL}/:id`,

  PRIVACY_POLICY: 'privacy-policy',
  PRIVACY_POLICY_SETTING: 'privacy-policy-setting',

  SPECIFIED_TRANSACTION_ART: 'specified-commercial-transactions',
  TERMS_OF_SERVICE: 'terms-of-service',
  PLAN_LIST: 'plan-list',
  PLAN_DETAIL: 'plan-detail',

  SHIPPING_LABEL_HISTORY: 'shipping-label-history',
  SHIPPING_LABEL_HISTORY_DETAIL: `${ACTION.DETAIL}/:id`,
  FEDEX: 'fedex',
  CHATWORK: 'chat-work',
  TASK_MANAGEMENT: 'task-management',
  AUTO_MESSAGE: 'auto-message',
  REQUEST_ADD_IMG: 'request-add-img',
  REQUEST_ADD_IMG_DETAIL: 'request-add-img/:id',
  FIRST_SETTING: 'first-setting',
};

export const USER_ROUTE_PATH = {
  //DASHBOARD
  DASHBOARD: `/${USER_ROUTE_NAME.DASHBOARD}`,

  //HOME
  HOME: `${USER}/${USER_ROUTE_NAME.HOME}`,

  //PROFILE
  PROFILE: `${USER}/${USER_ROUTE_NAME.PROFILE}`,

  //PROFILE
  CARD: `${USER}/${USER_ROUTE_NAME.EDIT_CARD}`,

  //SIGNIN
  SIGNIN: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNIN}`,

  //SIGNUP
  SIGNUP: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}`,
  CARD_INPUT: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}/${USER_ROUTE_NAME.CARD_INPUT}`,
  SIGNUP_OTP: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}/${USER_ROUTE_NAME.OTP_INPUT}`,
  SIGNUP_SUCCESS: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}/${USER_ROUTE_NAME.SUCCESS}`,
  SIGNUP_FAIL: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}/${USER_ROUTE_NAME.FAIL}`,
  VERIFY_FAIL: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.VERIFY}/${USER_ROUTE_NAME.FAIL}`,
  SELECT_PLAN_SIGNUP: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}/${USER_ROUTE_NAME.SELECT_PLAN}`,
  DETAIL_PLAN: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.SIGNUP}/${USER_ROUTE_NAME.DETAIL_PLAN}`,
  //FORGOT
  FORGOT: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.FORGOT}`,
  FORGOT_RESET_PASSWORD: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.FORGOT}/${USER_ROUTE_NAME.RESET_PASSWORD}`,
  FORGOT_OTP: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.FORGOT}/${USER_ROUTE_NAME.OTP_INPUT}`,
  FORGOT_SUCCESS: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.FORGOT}/${USER_ROUTE_NAME.SUCCESS}`,
  FORGOT_FAIL: `${USER}/${USER_ROUTE_NAME.AUTH}/${USER_ROUTE_NAME.FORGOT}/${USER_ROUTE_NAME.FAIL}`,
  //RETURN_MANAGEMENT
  RETURN_MANAGEMENT: `${USER}/${USER_ROUTE_NAME.RETURN_MANAGEMENT}`,
  RETURN_STORAGE: `${USER}/${USER_ROUTE_NAME.RETURN_MANAGEMENT}/${USER_ROUTE_NAME.RETURN_STORAGE}`,
  RETURN_ITEM: `${USER}/${USER_ROUTE_NAME.RETURN_ITEM}`,
  RETURN_ITEM_DETAIL: `${USER}/${USER_ROUTE_NAME.RETURN_ITEM}/${ACTION.DETAIL}`,
  RETURN_REQUEST_DETAIL: `${USER}/${USER_ROUTE_NAME.RETURN_MANAGEMENT}/${USER_ROUTE_NAME.RETURN_ITEM_DETAIL}`,

  //MEMBER_MANAGEMENT
  MEMBER_MANAGEMENT: `${USER}/${USER_ROUTE_NAME.MEMBER_MANAGEMENT}`,

  //SHIP_BUYER_REQUESTED
  SHIP_BUYER_REQUEST: `${USER}/${USER_ROUTE_NAME.SHIP_BUYER_REQUEST}/${ACTION.DETAIL}`,
  SHIP_BUYER_REQUESTED: `${USER}/${USER_ROUTE_NAME.SHIP_BUYER_REQUEST}/${ACTION.REQUEST}`,

  //MEMBER_MANAGEMENT
  PLAN_SETTING: `${USER}/${USER_ROUTE_NAME.PLAN_SETTING}`,
  SELECT_PLAN: `${USER}/${USER_ROUTE_NAME.PLAN_SETTING}/${USER_ROUTE_NAME.SELECT_PLAN}`,

  //SYSTEM_NOTIFICATION
  SYSTEM_NOTIFICATION: `${USER}/${USER_ROUTE_NAME.SYSTEM_NOTIFICATION}`,

  SETTING_NOTIFICATION: `${USER}/${USER_ROUTE_NAME.NOTIFY_SETTING}`,

  //EBAY_MANAGEMENT
  EBAY_MANAGEMENT: `${USER}/${USER_ROUTE_NAME.EBAY_MANAGEMENT}`,

  //SETTING_MANAGEMENT
  SETTING_MANAGEMENT: `${USER}/${USER_ROUTE_NAME.SETTING_MANAGEMENT}`,

  //CHAT_SUPPORT
  CHAT_SUPPORT: `${USER}/${USER_ROUTE_NAME.CHAT_SUPPORT}`,

  //PAYMENT_LIST_HISTORY
  PAYMENT_LIST_HISTORY: `${USER}/${USER_ROUTE_NAME.PAYMENT_LIST_HISTORY}`,
  PAYMENT_LIST_HISTORY_DETAIL: `${USER}/${USER_ROUTE_NAME.PAYMENT_LIST_HISTORY}/${ACTION.DETAIL}`,

  //Quit member
  QUIT_MEMBERSHIP: `${USER}/${USER_ROUTE_NAME.QUIT_MEMBERSHIP}`,

  //SUPPORT
  SUPPORT: `${USER}/${USER_ROUTE_NAME.SUPPORT}`,

  //FEDEX
  FEDEX: `${USER}/${USER_ROUTE_NAME.FEDEX}`,

  //CHATWORK
  CHATWORK: `${USER}/${USER_ROUTE_NAME.CHATWORK}`,

  //TASK_MANAGEMENT
  TASK_MANAGEMENT: `${USER}/${USER_ROUTE_NAME.TASK_MANAGEMENT}`,

  // SHIPPING_LABEL_HISTORY
  SHIPPING_LABEL_HISTORY: `${USER}/${USER_ROUTE_NAME.SHIPPING_LABEL_HISTORY}`,
  SHIPPING_LABEL_HISTORY_DETAIL: `${USER}/${USER_ROUTE_NAME.SHIPPING_LABEL_HISTORY}/${ACTION.DETAIL}`,

  //AUTO_MESSAGE
  AUTO_MESSAGE: `${USER}/${USER_ROUTE_NAME.AUTO_MESSAGE}`,

  //REQUEST_ADD_IMG
  REQUEST_ADD_IMG: `${USER}/${USER_ROUTE_NAME.REQUEST_ADD_IMG}`,
  REQUEST_ADD_IMG_DETAIL: `${USER}/${USER_ROUTE_NAME.REQUEST_ADD_IMG_DETAIL}`,

  //FIRST_SETTING
  FIRST_SETTING: `${USER}/${USER_ROUTE_NAME.FIRST_SETTING}`,
  //PRIVACY_POLICY
  PRIVACY_POLICY: `${USER}/${USER_ROUTE_NAME.PRIVACY_POLICY}`,
  //PRIVACY_POLICY
  PRIVACY_POLICY_SETTING: `${USER}/${USER_ROUTE_NAME.PRIVACY_POLICY_SETTING}`,
};

export const MEMBER_PERMISSIONS = {
  Authenticated: 'Authenticated',
  Setting: 'Setting',
  ProductManagement: 'ProductManagement',
  AccountManagement: 'AccountManagement',
  PaymentManagement: 'PaymentManagement',
  BizNotification: 'BizNotification',
  BizNotificationMail: 'BizNotificationMail',
  // EbayAccountManagement: 'EbayAccountManagement',
  // StorageRequest: 'StorageRequest',
  // ItemManagement: 'ItemManagement',
  // Setting: 'Setting',
  // CardManagement: 'CardManagement',
  // PlanSetting: 'PlanSetting',
  // ViewFee: 'ViewFee',
  // BizNotification: 'BizNotification',
  // SystemNotification: 'SystemNotification',
  // ChatSupport: 'ChatSupport',
  // Profile: 'Profile',
  // MemberManagement: 'MemberManagement',
};
export const STATUS_DETAIL_PAYMENT = {
  CHARGE_FOR_STORAGE_REQUEST: 'CHARGE_FOR_STORAGE_REQUEST',
  CHARGE_FOR_SHIP_TO_BUYER_REQUEST: 'CHARGE_FOR_SHIP_TO_BUYER_REQUEST',
  REFUND_WHEN_CANCEL_STORAGE_REQUEST: 'REFUND_WHEN_CANCEL_STORAGE_REQUEST',
};
