import { USER_ROUTE_NAME, USER_ROUTE_PATH } from '../constants/router';

export const CardSupport = ['Visa', 'MasterCard', 'JCB', 'American Express'];

export const TIME_FORMAT = {
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  MESSAGE_TIME: 'YYYY/MM/DD HH:mm',
  HOUSE_MINUTE: (type: '-' | '/' | ':') => `HH${type}mm`,
  DATE: (type: '-' | '/') => `YYYY${type}MM${type}DD`,
};

export const QUERY_KEY = {
  USER_ME: 'USER_ME',
  LIST_SETTING: 'LIST_SETTING',
  DEAIL_SETTING: 'DEAIL_SETTING',

  LIST_NOTIFI: 'LIST_NOTIFI',
  DEAIL_NOTIFI: 'DEAIL_NOTIFI',

  LIST_MEMBER: 'LIST_MEMBER',

  LIST_GROUP_CHAT: 'LIST_GROUP_CHAT',
  DETAIL_GROUP_CHAT: 'DETAIL_GROUP_CHAT',
  COUNT_USER_UNREAD_MESSAGE: 'COUNT_USER_UNREAD_MESSAGE',

  PAYMENT_SETTING_STATUS: 'PAYMENT_SETTING_STATUS',

  STORAGE_ITEM: 'STORAGE_ITEM',

  ITEM_DETAIL: 'ITEM_DETAIL',

  LIST_ACCOUNT_EBAY: 'LIST_ACCOUNT_EBAY',

  LIST_PAYMENT_HISTORY: 'LIST_PAYMENT_HISTORY',

  DETAIL_EXPORT_COUNT: 'DETAIL_EXPORT_COUNT',

  COUNT_USER_UNREAD_SYSTEM_NOTIFICATION: 'COUNT_USER_UNREAD_SYSTEM_NOTIFICATION',

  INFO_QUIT: 'QUIT_MEMBER',

  LIST_GROUP_ITEM_MESSAGE: 'LIST_GROUP_ITEM_MESSAGE',
  LIST_GROUP_ITEM_MESSAGE_UNREAD: 'LIST_GROUP_ITEM_MESSAGE_UNREAD',
  LIST_GROUP_ITEM_MESSAGE_INQUIRY: 'LIST_GROUP_ITEM_MESSAGE_INQUIRY',
  SETTING_NOTIFY: 'SETTING_NOTIFY',
  BIZ_NOTIFY: 'BIZ_NOTIFY',
  SETTING: 'SETTING',
  COUNT_ITEM_LIST: 'COUNT_ITEM_LIST',
};

export enum RETURN_STATUS_ENUM {
  CLOSED = 'CLOSED',
  ESCALATED = 'ESCALATED',
  ITEM_DELIVERED = 'ITEM_DELIVERED',
  ITEM_SHIPPED = 'ITEM_SHIPPED',
  LESS_THAN_A_FULL_REFUND_ISSUED = 'LESS_THAN_A_FULL_REFUND_ISSUED',
  PARTIAL_REFUND_DECLINED = 'PARTIAL_REFUND_DECLINED',
  PARTIAL_REFUND_FAILED = 'PARTIAL_REFUND_FAILED',
  PARTIAL_REFUND_INITIATED = 'PARTIAL_REFUND_INITIATED',
  PARTIAL_REFUND_REQUESTED = 'PARTIAL_REFUND_REQUESTED',
  READY_FOR_SHIPPING = 'READY_FOR_SHIPPING',
  REPLACED = 'REPLACED',
  REPLACEMENT_CLOSED = 'REPLACEMENT_CLOSED',
  REPLACEMENT_DELIVERED = 'REPLACEMENT_DELIVERED',
  REPLACEMENT_LABEL_REQUESTED = 'REPLACEMENT_LABEL_REQUESTED',
  REPLACEMENT_REQUESTED = 'REPLACEMENT_REQUESTED',
  REPLACEMENT_SHIPPED = 'REPLACEMENT_SHIPPED',
  REPLACEMENT_STARTED = 'REPLACEMENT_STARTED',
  REPLACEMENT_WAITING_FOR_RMA = 'REPLACEMENT_WAITING_FOR_RMA',
  RETURN_LABEL_REQUESTED = 'RETURN_LABEL_REQUESTED',
  RETURN_REJECTED = 'RETURN_REJECTED',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
  RETURN_REQUESTED_TIMEOUT = 'RETURN_REQUESTED_TIMEOUT',
  UNKNOWN = 'UNKNOWN',
  WAITING_FOR_RETURN_LABEL = 'WAITING_FOR_RETURN_LABEL',
  WAITING_FOR_RMA = 'WAITING_FOR_RMA',
}

export const STATUS_ITEM_MANAGEMENT = {
  REQUEST_CANCEL: 'CANCEL',
  NOT_DELIVERED: 'NOT_DELIVERED',
  DELIVERED: 'DELIVERED',
  WAITING_STORAGE: 'WAITING_STORAGE',
  WAITING_UPLOAD_IMAGE_VIDEO: 'WAITING_UPLOAD_IMAGE_VIDEO',
  IN_STORAGE: 'IN_STORAGE',
  SHIPPED_TO_BUYER: 'SHIPPED_TO_BUYER',
  SHIPPED_BACK: 'SHIPPED_BACK',
  DISPOSED: 'DISPOSED',
  IN_TRANSIT: 'IN_TRANSIT',
  NOT_SHIPPED: 'NOT_SHIPPED',
  SHIPPING_PREPARING: 'SHIPPING_PREPARING',
  SHIPPING_PREPARING_DONE: 'SHIPPING_PREPARING_DONE',
  NEW_DISPOSAL_REQUEST: 'NEW_DISPOSAL_REQUEST',
  PRICE_INPUTED: 'PRICE_INPUTED',
  CANCELED: 'CANCELED',
  SHIPPED: 'SHIPPED',
  IN_STORAGE_NOT_REFUNDED: 'IN_STORAGE_NOT_REFUNDED',
  INSTORAGE_REFUNDED: 'INSTORAGE_REFUNDED',
  SHIP_TO_BUYER_OPEN: 'SHIP_TO_BUYER_OPEN',
  SHIP_TO_BUYER_PREPARING_DONE: 'SHIP_TO_BUYER_PREPARING_DONE',
  SHIP_TO_BUYER_SHIPPED: 'SHIP_TO_BUYER_SHIPPED',
  SHIP_TO_BUYER_DELIVERED: 'SHIP_TO_BUYER_DELIVERED',
  DIPOSAL_OPEN: 'DIPOSAL_OPEN',
  DISPOSAL_WAIT: 'DISPOSAL_WAIT',
  DISPOSAL_DONE: 'DIPSAL_DONE',
  STORAGE_REQUEST_CANCELED: 'STORAGE_REQUEST_CANCELED',
  TEMPORARY_CANCELED: 'TEMPORARY_CANCELED',
};

export enum TAKE_PHOTO_REQUEST_STATUS {
  REQUESTED = 'REQUESTED',
  TAKE_BY_OPERATION = 'TAKE_BY_OPERATION',
  UPLOAD_BY_STAFF = 'UPLOAD_BY_STAFF',
}

export enum STORAGE_REQUEST_STATUS {
  NOT_SHIPPED = 'NOT_SHIPPED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum STORAGE_REQUEST_CANCEL_REASON {
  USER_CANCEL = 'USER_CANCEL',
  AUTO_CANCEL = 'AUTO_CANCEL',
}

export enum ShipingLabelsTrackingStatus {
  UNKNOWN = 'UNKNOWN',
  PRE_TRANSIT = 'PRE_TRANSIT',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  RETURNED = 'RETURNED',
  FAILURE = 'FAILURE',
}

export enum REQUEST_ACTION_ITEM_DETAIL {
  CREATE_DISPOSAL_REQUEST = 'CREATE_DISPOSAL_REQUEST',
  CANCEL_STORAGE_REQUEST = 'CANCEL_STORAGE_REQUEST',
  CANCEL_DISPOSAL_REQUEST = 'CANCEL_DISPOSAL_REQUEST',
  CANCEL_SHIP_TO_BUYER_REQUEST = 'CANCEL_SHIP_TO_BUYER_REQUEST',
  CANCEL_SHIP_BACK_REQUEST = 'CANCEL_SHIP_BACK_REQUEST',
}

export const NavigateBiz = {
  UnsubcriptionEvent: (id?: number) => USER_ROUTE_NAME.QUIT_MEMBERSHIP, // logo
  ItemReceivedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  ItemShippedEvent: (id?: number) => (id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM),
  NewInquiryMessageEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  StorageRequestAutoCancelEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  StorageRequestCompletedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  ShipRequestCompletedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  DisposalRequestCompletedEvent: (id?: number) =>
    id ? `${USER_ROUTE_PATH.RETURN_ITEM}/detail/${id}` : USER_ROUTE_PATH.RETURN_ITEM,
  EbayAccountLinkedEvent: () => USER_ROUTE_PATH.EBAY_MANAGEMENT,
  CardUpdatedEvent: (id?: number) => USER_ROUTE_PATH.CARD,
  MemberAddedEvent: (id?: number) => USER_ROUTE_PATH.MEMBER_MANAGEMENT,
  MemberDeletedEvent: (id?: number) => USER_ROUTE_PATH.MEMBER_MANAGEMENT,
  EmailUpdatedEvent: (id?: number) => USER_ROUTE_PATH.PROFILE,
};

export enum FedExShippingCode {
  'NEW' = 'NEW',
  'AA' = '返送中',
  'AC' = 'At Canada Post facility',
  'AD' = '返送中',
  'AF' = '返送中',
  'AO' = '返送中',
  'AP' = 'バイヤー発送前',
  'AR' = '返送中',
  'AX' = '返送中',
  'CA' = 'キャンセル済',
  'CH' = '返送中',
  'DD' = '返送中',
  'DE' = '返送中',
  'DL' = '配達完了',
  'DP' = '返送中',
  'DR' = 'Vehicle furnished but not used',
  'DS' = '返送中',
  'DY' = '遅延',
  'EA' = '返送中',
  'ED' = '返送中',
  'EO' = '返送中',
  'EP' = 'バイヤー発送前',
  'FD' = '返送中',
  'HL' = '返送中',
  'HP' = 'バイヤー発送前',
  'IT' = '返送中',
  'IX' = '返送中',
  'LO' = '返送中',
  'OC' = 'バイヤー発送前',
  'OD' = '返送中',
  'OF' = '返送中',
  'OX' = 'Shipment information sent to USPS',
  'PD' = 'バイヤー発送前',
  'PF' = '返送中',
  'PL' = '返送中',
  'PM' = '返送中',
  'PU' = '返送中',
  'PX' = '返送中',
  'RR' = '返送中',
  'RM' = '返送中',
  'RC' = '返送中',
  'RS' = 'バイヤーに返送',
  'RP' = 'Return label link emailed to return sender',
  'LP' = 'Return label link cancelled by shipment originator',
  'RG' = 'Return label link expiring soon',
  'RD' = 'Return label link expired',
  'SE' = '遅延',
  'SF' = '返送中',
  'SP' = 'Split Status',
  'TR' = '返送中',
}

export enum EBAY_REASON_RETURN_TROUBLE {
  ARRIVED_DAMAGED = 'ARRIVED_DAMAGED',
  DEFECTIVE_ITEM = 'DEFECTIVE_ITEM',
  DIFFERENT_FROM_LISTING = 'DIFFERENT_FROM_LISTING',
  FAKE_OR_COUNTERFEIT = 'FAKE_OR_COUNTERFEIT',
  FOUND_BETTER_PRICE = 'FOUND_BETTER_PRICE',
  MISSING_PARTS = 'MISSING_PARTS',
  NO_LONGER_NEED_ITEM = 'NO_LONGER_NEED_ITEM',
  NOT_AS_DESCRIBED = 'NOT_AS_DESCRIBED',
  ORDERED_ACCIDENTALLY = 'ORDERED_ACCIDENTALLY',
  ORDERED_DIFFERENT_ITEM = 'ORDERED_DIFFERENT_ITEM',
  ORDERED_WRONG_ITEM = 'ORDERED_WRONG_ITEM',
  WRONG_SIZE = 'WRONG_SIZE',
}
