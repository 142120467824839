import { StorageItem } from '../../../../apis/client-axios';
import RowDetail from '../../../../components/InfoRow/NewRowDetail';
import { decode as decodeHtml } from 'he';
import { getLinkItemEbayNavigate, resizeImageItemEbay } from '../../../../util/function-util';
import { Helper } from '../../../../util/helper';

type TProps = {
  itemDetail?: StorageItem;
  src?: string;
  internalSku?: string;
  itemName?: string;
  itemId?: string;
  itemSku?: string;
  price?: number;
  accountEbay?: string;
};

const ProductInfo = ({ itemDetail, src, internalSku, itemName, itemId, itemSku, price, accountEbay }: TProps) => {
  return (
    <div className="box product-info">
      <div className="title">
        <h3>商品情報</h3>
      </div>
      <div className="box__wrap">
        <div className="product-info__img">
          <img
            src={
              itemDetail
                ? itemDetail?.ebayReturns?.ebayItemImage
                  ? resizeImageItemEbay(itemDetail?.ebayReturns?.ebayItemImage, 500)
                  : itemDetail?.preview?.source
                    ? Helper.getSourceFile(itemDetail?.preview?.source)
                    : null
                : src
                  ? src
                  : null
            }
            alt=""
          />
        </div>
        <div className="list-info list-info__product">
          <RowDetail label="BayPack管理番号" value={itemDetail ? itemDetail?.internalSku : internalSku} />
          <RowDetail
            label="商品名"
            value={decodeHtml(itemDetail ? itemDetail?.itemName : itemName ? itemName : '')}
            nameRow
            isLink={true}
            href={getLinkItemEbayNavigate(itemDetail ? itemDetail?.ebayReturns?.ebayItemId : itemId)}
            underline={true}
            rows={2}
          />
          <RowDetail label="セラーSKU" value={itemDetail ? itemDetail?.itemSku : itemSku} />
          <RowDetail
            label="申告価格"
            value={
              itemDetail?.itemPrice ? `$${Number(itemDetail?.itemPrice.toFixed(2))}` : price ? `$${Number(price)}` : ''
            }
          />
          <RowDetail
            label="アカウント名"
            value={itemDetail ? itemDetail?.ebayReturns?.accountEbay?.name : accountEbay ? accountEbay : ''}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
