import { Layout, MenuProps } from 'antd';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../constants/router';
import { TAB_SIZE } from '../../constants/ThemeSetting';
import { RootState } from '../../store';
import SidebarContent from '../SideBar/SidebarContent';
import Topbar from '../TopBar';
import './style.scss';
const { Content, Sider, Footer } = Layout;

const URL_PADDING_LIST = [
  '/return-management',
  '/return-item',
  '/shipping-label-history',
  '/first-setting',
  '/privacy-policy',
];

interface IMainAppProp {
  menuItems?: MenuProps['items'];
  infoDropdownItems?: MenuProps['items'];
  extraTopbar?: ReactNode;
}
export const MainAppContext = createContext(null);
const MainApp = (props: IMainAppProp) => {
  const { width } = useSelector((state: RootState) => state.setting);
  const [collapsed, setCollapsed] = useState(width < TAB_SIZE);
  const authUser = useSelector((state: RootState) => state.auth).authUser;
  const [menu, setMenu] = useState<any[]>([]);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [imageLineItem, setImageLineItem] = useState('');

  useEffect(() => {
    if (location.pathname !== USER_ROUTE_PATH.SIGNUP) {
      sessionStorage.removeItem('signUpFormValues');
    }

    const checkCollapsed =
      [USER_ROUTE_PATH.RETURN_ITEM, USER_ROUTE_PATH.SHIPPING_LABEL_HISTORY].includes(location.pathname) ||
      (USER_ROUTE_PATH.RETURN_MANAGEMENT.includes(location.pathname) && location.state?.page === 'LIST');

    setCollapsed(!checkCollapsed);
  }, [location.pathname, location.state]);

  const hiddenSiderRoutes = [
    '/' + USER_ROUTE_NAME.SETTING_MANAGEMENT,
    '/' + USER_ROUTE_NAME.EBAY_MANAGEMENT,
    '/' + USER_ROUTE_NAME.PROFILE,
    '/' + USER_ROUTE_NAME.SYSTEM_NOTIFICATION,
    '/' + USER_ROUTE_NAME.EDIT_CARD,
    '/' + USER_ROUTE_NAME.MEMBER_MANAGEMENT,
    '/' + USER_ROUTE_NAME.PLAN_SETTING,
    '/' + USER_ROUTE_NAME.QUIT_MEMBERSHIP,
    '/' + USER_ROUTE_NAME.PLAN_SETTING + '/detail/',
    '/' + USER_ROUTE_NAME.PAYMENT_LIST_HISTORY,
    '/' + USER_ROUTE_NAME.SUPPORT,
    '/' + USER_ROUTE_NAME.FEDEX,
    '/' + USER_ROUTE_NAME.CHATWORK,
    '/' + USER_ROUTE_NAME.TASK_MANAGEMENT,
    '/' + USER_ROUTE_NAME.AUTO_MESSAGE,
    '/' + USER_ROUTE_NAME.FIRST_SETTING,
    '/' + USER_ROUTE_NAME.PRIVACY_POLICY,
  ];
  const shouldHideSider = hiddenSiderRoutes.some((route) => location.pathname.startsWith(route));

  useEffect(() => {
    let data: any[] = [];
    if (authUser?.parentId != null && props.menuItems) {
      authUser?.permissions?.map((role) => {
        props.menuItems?.map((item: any) => {
          if (Array.isArray(item.permission)) {
            for (const i of item.permission) {
              if (i && authUser?.permissions.includes(i)) {
                if (!data.includes(item)) data.push(item);
                break;
              }
            }
          }
        });
      });
      setMenu(data);
    } else {
      setMenu(props.menuItems as []);
    }
  }, [authUser, props.menuItems]);

  useEffect(() => {
    if (location.pathname !== USER_ROUTE_PATH.SIGNUP) {
      sessionStorage.removeItem('signUpFormValues');
    }
  }, [location.pathname]);

  return (
    <Layout>
      <MainAppContext.Provider
        value={{
          isOpen: isOpen,
          setIsOpen: setIsOpen,
          imageLineItem: imageLineItem,
          setImageLineItem: setImageLineItem,
        }}
      >
        <Layout
          onClick={(e) => {
            try {
              if (isOpen) {
                if (
                  (e?.target as any)?.tagName == 'svg' ||
                  (e?.target as any)?.tagName == 'path' ||
                  (e?.target as any)?.className?.includes('ant-layout-header')
                )
                  setIsOpen(false);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Topbar
            collapsed={collapsed}
            onCollapsed={() => setCollapsed(!collapsed)}
            infoDropdownItems={props.infoDropdownItems}
            menu={menu}
          >
            {props.extraTopbar}
          </Topbar>
        </Layout>
        <Layout
          className="layout"
          style={{ height: 'calc(100vh - 58px)', marginBottom: '-2px', marginTop: '-2px', background: '#f47920' }}
          hasSider={!shouldHideSider}
        >
          {!shouldHideSider && (
            <Sider
              className={`layout-sider-${collapsed ? 'small' : 'default'}`}
              trigger={null}
              collapsible
              // collapsed={width < TAB_SIZE ? false : collapsed}
              collapsed={collapsed}
              // onClick={() => setCollapsed(!collapsed)}
            >
              <SidebarContent menuItems={menu} collapsed={collapsed} setCollapsed={setCollapsed} />
              {/* {width < TAB_SIZE ? (
                <Drawer open={collapsed} placement="left" closable={false} onClose={() => setCollapsed(!collapsed)}>
                  <SidebarContent menuItems={menu} collapsed={collapsed} setCollapsed={setCollapsed} />
                </Drawer>
              ) : (
                <SidebarContent menuItems={menu} collapsed={collapsed} setCollapsed={setCollapsed} />
              )} */}
            </Sider>
          )}
          <Content
            onClick={() => {
              if (isOpen) setIsOpen(false);
            }}
            className={`layout-detail`}
            style={{ paddingBottom: URL_PADDING_LIST.includes(location?.pathname) ? '16px' : '0px' }}
          >
            <Outlet />
          </Content>
        </Layout>
      </MainAppContext.Provider>
    </Layout>
  );
};
export default MainApp;
