import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Modal, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import axios, { AxiosError } from 'axios';
import { decode as decodeHtml } from 'he';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ebayManagerApi, shippoApi, storageItemApi, storageProcessApi } from '../../../../apis';
import {
  CreateShipmentDto,
  CreateShipToBuyerDto,
  ParcelsDtoDistanceUnitEnum,
  ParcelsDtoMassUnitEnum,
  ShippingAddress,
} from '../../../../apis/client-axios';
import { SvgArrowDownSelect } from '../../../../components/@svg/SvgArrowDownSelect';
import ButtonAgree from '../../../../components/buttonCustom/ButtonAgree';
import ButtonAgreeWhite from '../../../../components/buttonCustom/ButtonAgreeWhite';
import ButtonCancel from '../../../../components/buttonCustom/ButtonCancel';
import CustomInput from '../../../../components/input/CustomInput';
import { ModalInformation } from '../../../../components/ModalInformation';
import NotificationError from '../../../../components/notification/NotificationError';
import NotificationSuccess from '../../../../components/notification/NotificationSuccess';
import CustomSelect from '../../../../components/select/CustomSelect';
import { StyledModal } from '../../../../components/StyledModal';
import { USER_ROUTE_PATH } from '../../../../constants/router';
import { MainAppContext } from '../../../../containers/App/MainApp';
import { TMessagesCarrier } from '../../return/storage-request';
import './ship2buyerRequest.scss';
import CustomButton from '../../../../components/buttons/CustomButton';
import moment from 'moment';
import ModalError from '../../../../components/ModalError';
import { BackIcon } from '../../../../components/@svg/BackIcon';
import EditDetailBuyer from '../components/EditDetailBuyer';
import SizePackage from '../components/SizePackage';
import Carrier from '../components/Carrier';
import SvgIconError from '../../../../components/@svg/SvgIconError';
import Bill from '../components/Bill';
import ProductInfo from '../components/ProductInfo';
import ShipInfo from '../components/ShipInfo';
import SizeInfo from '../components/sizeInfo/SizeInfo';
import MemoInfo from '../components/MemoInfo';
import { QUERY_KEY } from '../../../../util/constants';
import { containsNonLatinCharacters, containsSpecialCharacters } from '../../../../util/function-util';
import { ErrorMessage } from '../../../../components/response/error.message';

export type TDataRequest = {
  carrier: string;
  carrierPrice: number;
  errorInputHScode: boolean;
  errorInputItemPrice: boolean;
  HSCode: string;
  insurance: number;
  isInsurance: boolean;
  itemPrice: number;
  openModalInformation: boolean;
  orderAccountEbayId: number;
  orderBuyerEiasToken: string;
  orderItemId: string;
  orderItemName: string;
  orderItemPrice: number;
  orderItemPriceCurrency: string;
  orderItemSKU: string;
  orderStatus: string;
  quantityPurchased: number;
  shippingPlan: string;
  shippingRate: string;
  transactionId: string;
  shipByDate: string;
  confirm: boolean;
  imgVariant: string;
  internalSku: string;
  battery: string;
};

export default function Ship2BuyerRequest() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const itemStorage = state['itemStorage'];
  const orderId = state['orderId'];
  const itemId = state['itemId'];
  const userEbayId = state['userEbayId'];
  const IOSS = state['IOSS'];
  const phone = state['phone'];
  const lineItemID = state['lineItemID'];
  const shipByDate = state['shipByDate'];
  const { imageLineItem } = useContext(MainAppContext);
  const [modalEditAddress, setModalEditAddress] = useState<{
    isOpen: boolean;
    isChange: boolean;
    data: Omit<ShippingAddress, 'AddressID' | 'AddressOwner' | 'CountryName'>;
  }>({
    isOpen: false,
    isChange: false,
    data: {
      Name: '',
      Street1: '',
      Street2: '',
      CityName: '',
      PostalCode: '',
      StateOrProvince: '',
      Country: '',
      Phone: phone,
    },
  });
  const labelFormEdit = {
    Name: '名前',
    Street1: 'Street',
    Street2: 'Street (Line 2)',
    CityName: 'City',
    StateOrProvince: 'State Or Province',
    Country: 'Country',
    Phone: '電話番号',
    PostalCode: 'Postal code/zip',
  };
  const [formEditAddress] = useForm<typeof modalEditAddress.data>();
  const [dataRequest, setDataRequest] = useState<TDataRequest>({
    carrier: '',
    carrierPrice: 0,
    errorInputHScode: false,
    errorInputItemPrice: false,
    HSCode: '',
    insurance: 0,
    isInsurance: false,
    itemPrice: 0,
    openModalInformation: false,
    orderAccountEbayId: 0,
    orderBuyerEiasToken: '',
    orderItemId: '',
    orderItemName: '',
    orderItemPrice: 0,
    orderItemPriceCurrency: '',
    orderItemSKU: '',
    orderStatus: '',
    quantityPurchased: 0,
    shippingPlan: '',
    shippingRate: '',
    transactionId: '',
    shipByDate: '',
    confirm: false,
    imgVariant: '',
    internalSku: '',
    battery: '',
  });
  const [openModalShipByDate, setOpenModalShipByDate] = useState(false);
  const [openModalError, setOpenModalError] = useState({ isShow: false, text: '', isLink: false, nameModal: '' });
  const isInvalidSpecialCharacters = (addressData) => {
    const fieldsToValidate = [
      addressData.Street1,
      addressData.Street2,
      addressData.CityName,
      addressData.StateOrProvince,
      addressData.PostalCode,
    ];
    return fieldsToValidate.some(containsSpecialCharacters);
  };

  const isInvalidNonLatin = (addressData) => {
    const fieldsToValidate = [
      addressData.Street1,
      addressData.Street2,
      addressData.CityName,
      addressData.StateOrProvince,
      addressData.PostalCode,
    ];
    return fieldsToValidate.some(containsNonLatinCharacters);
  };

  const { mutate: CreateShipToBuyer, isPending: loadingCreateShip2Buyer } = useMutation({
    mutationKey: ['submit'],
    mutationFn: (data: CreateShipToBuyerDto) =>
      storageProcessApi.storageProcessControllerCreateShipToBuyerRequest(data, {
        headers: { 'skip-error-handler': true },
      }),
    onSuccess: ({ data }) => {
      NotificationSuccess({ content: '発送依頼が登録されました。' });
      navigate(`${USER_ROUTE_PATH.RETURN_ITEM}`);
    },
    onError: ({ response: { data } }: AxiosError) => {
      if ((data as any)?.message.includes('LABEL_CREATED_ERROR')) {
        setOpenModalError({
          ...openModalError,
          isShow: true,
          isLink: true,
          text: '<p>ラベル発行が失敗しました。<br />BayPack担当者にお問合せください。</p>',
          nameModal: 'LABEL_CREATED_ERROR',
        });
      } else if ((data as any)?.message.includes('CHARGE_MONEY_FAILED')) {
        setOpenModalError({
          ...openModalError,
          isShow: true,
          isLink: false,
          text: '<p>決済が失敗しました。<br />カード情報を確認してください。</p>',
          nameModal: 'CHARGE_MONEY_FAILED',
        });
      } else {
        setOpenModalError({
          ...openModalError,
          isShow: true,
          isLink: true,
          text: '<p>エラーが発生しました。<br />BayPack担当者にお問合せください。</p>',
          nameModal: 'BAYPACK_ERROR',
        });
      }
    },
  });
  const {
    data: itemDetail,
    mutate: getOneItem,
    isPending: loadingGetOne,
  } = useMutation({
    mutationKey: [itemStorage],
    mutationFn: (id: string) => storageItemApi.storageItemControllerGetOneItemManagementUser(id),
    onError: ({ response: { data } }: AxiosError) => {},
  });

  const {
    data: carrier,
    mutate: getCarrier,
    isPending: loadingCarrier,
  } = useMutation({
    mutationKey: ['carrier'],
    mutationFn: (data: CreateShipmentDto) =>
      shippoApi.shippoApiControllerCreateShipment(data, {
        headers: { 'skip-error-handler': true },
      }),
    onSuccess: ({ data }) => {
      const listRates = Object.values(data.rates).filter((v) => v);
      if (!listRates.length) {
        const messages =
          data?.messages?.filter(
            (v) => v['text'] != 'Shipment origin is out of service area for UPS Master account from CA',
          ) || [];

        messages.length
          ? messages?.forEach((v: TMessagesCarrier) => {
              const split = v.text.split(': ');
              const content = `${v.source}: ${split[1] ?? split[0] ?? 'ERROR'}`;
              NotificationError({ content });
            })
          : NotificationError({ content: ErrorMessage[500] });
      }
    },
    onError: ({ response: { data } }: AxiosError) => {
      if ((data as any)?.message.includes('LABEL_CREATED_ERROR')) {
        setOpenModalError({
          ...openModalError,
          isShow: true,
          isLink: true,
          text: '<p>ラベル発行が失敗しました。<br />BayPack担当者にお問合せください。</p>',
          nameModal: 'LABEL_CREATED_ERROR',
        });
      } else if ((data as any)?.message.includes('CHARGE_MONEY_FAILED')) {
        setOpenModalError({
          ...openModalError,
          isShow: true,
          isLink: false,
          text: '<p>決済が失敗しました。<br />カード情報を確認してください。</p>',
          nameModal: 'CHARGE_MONEY_FAILED',
        });
      } else {
        setOpenModalError({
          ...openModalError,
          isShow: true,
          isLink: true,
          text: '<p>エラーが発生しました。<br />BayPack担当者にお問合せください。</p>',
          nameModal: 'BAYPACK_ERROR',
        });
      }
    },
  });

  const {
    data: orderEbay,
    mutate: getOneOrder,
    isPending: loadingGetOneOrder,
  } = useMutation({
    mutationKey: [orderId],
    mutationFn: (data: { orderId: string; userEbayId: string }) =>
      ebayManagerApi.ebayManagerControllerGetOneOrderReturnFromEbay(data.orderId, data.userEbayId),
    onError: ({ response: { data } }: AxiosError) => {},
  });
  const fetchApi = async () => await Promise.all([getOneItem(itemStorage), getOneOrder({ orderId, userEbayId })]);

  const { data: listAccountEbay, refetch: refetchCount } = useQuery({
    queryKey: [QUERY_KEY.LIST_ACCOUNT_EBAY],
    queryFn: () =>
      ebayManagerApi.ebayManagerControllerGetAllAccountEbay(1, undefined, undefined, undefined, undefined, false),
    staleTime: 1000,
  });

  const { data: listCountries } = useQuery({
    queryKey: ['listCountries'],
    queryFn: () =>
      axios
        .get('https://restcountries.com/v3.1/all?fields=name,cca2')
        .then(({ data }) =>
          (data as [])
            .map((v: any) => ({ label: v?.name?.common, value: v?.cca2 }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ),
  });

  const handleClickBack = () => {
    return dataRequest.confirm
      ? setDataRequest({ ...dataRequest, confirm: false })
      : navigate(`${USER_ROUTE_PATH.SHIP_BUYER_REQUEST}/${itemStorage}`);
  };

  const handleSubmit = () => {
    if (dataRequest.confirm) {
      CreateShipToBuyer({
        hsCode: dataRequest.HSCode,
        ioss: IOSS ?? '',
        isInsurance: dataRequest.isInsurance,
        itemPrice: dataRequest.itemPrice ?? dataRequest.orderItemPrice,
        orderAccountEbayId: dataRequest.orderAccountEbayId,
        orderBuyerCountry: modalEditAddress.data.Country,
        orderBuyerEiasToken: dataRequest.orderBuyerEiasToken,
        orderBuyerId: orderEbay?.data?.BuyerUserID,
        orderId: orderId,
        orderItemId: dataRequest.orderItemId,
        orderItemName: decodeHtml(dataRequest.orderItemName || ''),
        orderItemPrice: dataRequest.orderItemPrice,
        orderItemPriceCurrency: dataRequest.orderItemPriceCurrency,
        orderItemSKU: dataRequest.orderItemSKU,
        orderShippingAddress: modalEditAddress.data.Street1,
        orderShippingAddress2: modalEditAddress.data.Street2,
        orderShippingName: modalEditAddress.data.Name,
        orderShippingTelNo: modalEditAddress.data.Phone,
        orderShippingZipCode: modalEditAddress.data.PostalCode,
        orderStatus: dataRequest.orderStatus,
        orderTransactionId: dataRequest.transactionId,
        quantityPurchased: dataRequest.quantityPurchased,
        shippingAddress: modalEditAddress.data.Street1,
        shippingAddress2: modalEditAddress.data.Street2,
        shippingCarrier: dataRequest.carrier,
        shippingName: modalEditAddress.data.Name,
        shippingPlan: dataRequest.shippingPlan,
        shippingRate: dataRequest.shippingRate,
        shippingTelNo: modalEditAddress.data.Phone,
        shippingZipCode: modalEditAddress.data.PostalCode,
        storageItemId: +itemStorage,
        lineItemId: lineItemID,
        shipByDate: dataRequest.shipByDate,
        battery: dataRequest?.battery.includes('バッテリーなし') ? null : dataRequest?.battery,
      });
    } else {
      const checkExpire = moment().isAfter(moment(dataRequest.shipByDate).subtract(48, 'hours'));
      const check = dataRequest.confirm && checkExpire;

      if (isInvalidSpecialCharacters(modalEditAddress.data)) {
        NotificationError({
          content: '住所 は特殊文字を含むことはできません',
        });
        return;
      }

      if (isInvalidNonLatin(modalEditAddress.data)) {
        NotificationError({
          content: '英数字を入力してください。',
        });
        return;
      }
      if (check) {
        setOpenModalShipByDate(true);
      }
      setDataRequest({ ...dataRequest, confirm: true });
    }
  };
  const handleFinishEditAddress = (data: typeof modalEditAddress.data) => {
    const fieldsToValidate = [
      data.Street1,
      data.Street2,
      data.CityName,
      data.StateOrProvince,
      data.PostalCode,
      data.Phone,
    ];

    let hasError = false;

    if (fieldsToValidate.some(containsSpecialCharacters)) {
      NotificationError({ content: '住所 は特殊文字を含むことはできません' });
      hasError = true;
    }

    if (fieldsToValidate.some(containsNonLatinCharacters)) {
      NotificationError({ content: '英数字を入力してください。' });
      hasError = true;
    }

    if (hasError) return;

    const isChange = Object.keys(data).some((key) => data[key] !== modalEditAddress.data[key]);

    setModalEditAddress({
      data: isChange ? data : { ...modalEditAddress.data },
      isChange,
      isOpen: false,
    });
  };

  const handleCloseModalShipByDate = () => {
    setOpenModalShipByDate(false);
  };

  const handleCloseModalError = () => {
    setOpenModalError({ ...openModalError, isShow: false });
  };

  useEffect(() => {
    if (orderId && itemStorage && userEbayId) fetchApi();
  }, [orderId, itemStorage, userEbayId]);

  useEffect(() => {
    if (orderEbay?.data?.BuyerUserID && itemDetail?.data?.id) {
      const Transaction = orderEbay?.data?.TransactionArray?.Transaction as any; // type
      const item = Array.isArray(Transaction) ? Transaction?.find((v) => v.Item.ItemID === itemId) : Transaction;
      const orderItemPrice = +item?.TransactionPrice?.['_@ttribute'];
      const orderShippingTelNo =
        orderEbay?.data?.ShippingAddress?.Phone !== 'Invalid Request' ? orderEbay?.data?.ShippingAddress?.Phone : phone;
      const userAccountId =
        listAccountEbay?.data && listAccountEbay?.data?.content.find((item) => item.userEbayId === userEbayId);
      setDataRequest({
        ...dataRequest,
        insurance: +((1.25 * orderItemPrice) / 100).toFixed(2),
        itemPrice: orderItemPrice,
        orderAccountEbayId: userAccountId.id,
        orderBuyerEiasToken: orderEbay?.data?.EIASToken,
        orderItemId: item?.Item?.ItemID,
        orderItemName: decodeHtml(item?.Item?.Title || ''),
        orderItemPrice: orderItemPrice,
        orderItemPriceCurrency: item?.TransactionPrice?.currencyID,
        orderItemSKU: item?.Variation?.SKU || item?.Item?.SKU,
        orderStatus: orderEbay?.data?.OrderStatus,
        quantityPurchased: +item?.QuantityPurchased,
        transactionId: item?.TransactionID,
        shipByDate: shipByDate,
        imgVariant: imageLineItem,
        internalSku: itemDetail?.data?.internalSku,
      });

      if (orderEbay?.data?.ShippingAddress) {
        const data = cloneDeep(modalEditAddress.data);
        Object.keys(modalEditAddress.data).forEach((key: keyof typeof modalEditAddress.data) => {
          key === 'Phone'
            ? (data[key] = orderShippingTelNo)
            : (data[key] = orderEbay?.data?.ShippingAddress?.[key] || '');
        });
        setModalEditAddress({ ...modalEditAddress, data });
      }
    }
  }, [orderEbay, itemDetail]);

  useEffect(() => {
    if (
      itemDetail?.data?.id &&
      dataRequest.orderItemId &&
      !dataRequest.errorInputHScode &&
      !dataRequest.errorInputItemPrice
    ) {
      const fieldsToValidate = [
        modalEditAddress.data.Street1,
        modalEditAddress.data.Street2,
        modalEditAddress.data.CityName,
        modalEditAddress.data.StateOrProvince,
        modalEditAddress.data.PostalCode,
      ];

      let hasError = false;

      if (fieldsToValidate.some(containsSpecialCharacters)) {
        NotificationError({ content: '住所 は特殊文字を含むことはできません' });
        hasError = true;
      }

      if (fieldsToValidate.some(containsNonLatinCharacters)) {
        NotificationError({ content: '英数字を入力してください。' });
        hasError = true;
      }
      if (hasError) return;

      let bodyGetShipment: CreateShipmentDto = {
        ebayAccountId: itemDetail?.data?.ebayReturns?.accountEbayId,
        address_to: {
          name: modalEditAddress.data.Name,
          street1: modalEditAddress.data.Street1 || modalEditAddress.data.Street2,
          street2: modalEditAddress.data.Street2 ?? '',
          city: modalEditAddress.data.CityName,
          state: modalEditAddress.data.StateOrProvince,
          zip: modalEditAddress.data.PostalCode,
          country: modalEditAddress.data.Country,
          phone: modalEditAddress.data.Phone,
        },
        parcels: [
          {
            distance_unit: ParcelsDtoDistanceUnitEnum.Cm,
            height: String(itemDetail?.data?.storageHeight),
            length: String(itemDetail?.data?.storageLength),
            mass_unit: ParcelsDtoMassUnitEnum.Kg,
            weight: String(itemDetail?.data?.storageWeight),
            width: String(itemDetail?.data?.storageWidth),
            metadata: '',
          },
        ],
        customs_declaration: {
          items: [
            {
              quantity: 1,
              description: itemDetail?.data?.itemEditName || '',
              mass_unit: 'kg',
              origin_country: 'US',
              hs_code: dataRequest.HSCode,
              sku_code: itemDetail?.data?.itemSku,
              net_weight: String(itemDetail?.data?.storageWeight),
              value_amount: String(dataRequest.itemPrice),
              value_currency: 'USD',
            },
          ],
        },
      };
      const { street1, street2, city, state, zip } = bodyGetShipment.address_to;
      const fieldsToValidate2 = [street1, street2, city, state, zip];

      let hasError2 = false;

      if (fieldsToValidate2.some(containsSpecialCharacters)) {
        hasError2 = true;
      }

      if (fieldsToValidate2.some(containsNonLatinCharacters)) {
        hasError2 = true;
      }

      if (hasError2) return;

      if (IOSS && IOSS != '') {
        bodyGetShipment = {
          ...bodyGetShipment,
          customs_declaration: {
            ...bodyGetShipment.customs_declaration,
            exporter_identification: {
              tax_id: {
                type: 'IOSS',
                number: IOSS.replace(/\s+/g, ''),
              },
            },
          },
        };
      }

      getCarrier(bodyGetShipment);
      setDataRequest({
        ...dataRequest,
        carrier: '',
        carrierPrice: 0,
        shippingPlan: '',
        shippingRate: '',
      });
    }
  }, [dataRequest.HSCode, dataRequest.itemPrice, itemDetail, modalEditAddress.data]);

  useEffect(() => {
    if (modalEditAddress.isOpen) {
      formEditAddress.setFieldsValue(modalEditAddress.data);
    }
  }, [modalEditAddress.isOpen]);

  return (
    <div className="request-ship-2-buyer">
      {itemDetail?.data && (
        <>
          <div className="request-ship-2-buyer__btn-back" onClick={handleClickBack}>
            <BackIcon />
          </div>
          <div className="request-ship-2-buyer-container">
            <p className="title">{dataRequest.confirm ? '発送依頼（最終確認）' : '発送依頼（配送情報）'}</p>
            <div className="content-ship">
              <div className="left">
                {dataRequest.confirm && (
                  <ProductInfo
                    src={dataRequest.imgVariant}
                    itemName={dataRequest?.orderItemName}
                    itemId={dataRequest?.orderItemId}
                    itemSku={dataRequest?.orderItemSKU}
                    price={dataRequest?.itemPrice}
                    internalSku={dataRequest?.internalSku}
                    accountEbay={userEbayId}
                  />
                )}
                <div className="left__detail-ship">
                  {dataRequest.confirm && <ShipInfo orderEbay={orderEbay} />}
                  <EditDetailBuyer
                    orderEbay={orderEbay}
                    modalEditAddress={modalEditAddress}
                    setModalEditAddress={setModalEditAddress}
                    dataRequest={dataRequest}
                  />
                </div>
                {!dataRequest.confirm ? (
                  <>
                    <SizePackage
                      dataRequest={dataRequest}
                      itemDetail={itemDetail}
                      setDataRequest={setDataRequest}
                      orderEbay={orderEbay}
                    />
                    <Carrier
                      carrier={carrier}
                      loadingCarrier={loadingCarrier}
                      dataRequest={dataRequest}
                      setDataRequest={setDataRequest}
                      itemDetail={itemDetail}
                      modalEditAddress={modalEditAddress}
                    />
                  </>
                ) : (
                  <div className="left__detail-ship">
                    <SizeInfo itemDetail={itemDetail} title="パッケージ情報" />
                    <MemoInfo itemDetail={itemDetail} />
                  </div>
                )}
              </div>
              <div className="right">
                <Bill
                  dataRequest={dataRequest}
                  handleClickBack={handleClickBack}
                  handleSubmit={handleSubmit}
                  carrier={carrier}
                  orderByCountry={orderEbay?.data?.ShippingAddress?.Country}
                />
              </div>
            </div>
          </div>
          <ModalInformation
            content={
              'USPSは梱包サイズや重量が違うと、追加料金が発生しやすい傾向があります。USPSが1割ほど安いくらいの違いであれば、USPS以外をお勧めします。'
            }
            onCancel={() => setDataRequest({ ...dataRequest, openModalInformation: false })}
            open={dataRequest.openModalInformation}
            title={'USPS'}
          />

          {openModalShipByDate && (
            <Modal
              className="modal-confirm-ship-by-date"
              open={true}
              closeIcon={false}
              onCancel={handleCloseModalShipByDate}
              centered
              footer={[]}
            >
              <div className="content">
                <SvgIconError />
                <p>
                  発送期限まで48時間をきっていますので、期限まで
                  <br />
                  に発送完了できない可能性があります。適宜、お客
                  <br />
                  様にて先に追跡番号（商品詳細画面で確認可）の
                  <br />
                  ebayへの登録をお願いします。
                </p>
              </div>
              <CustomButton onClick={handleCloseModalShipByDate} className="modal-confirm-ship-by-date__cancel">
                閉じる
              </CustomButton>
            </Modal>
          )}

          {openModalError.isShow && (
            <ModalError
              isOpen={openModalError.isShow}
              handleCancelModal={handleCloseModalError}
              isLink={openModalError.isLink}
              className={`${openModalError.nameModal.includes('CHARGE_MONEY_FAILED') ? 'charge-error' : ''}`}
            >
              <div dangerouslySetInnerHTML={{ __html: openModalError.text }} />
            </ModalError>
          )}

          <StyledModal
            children={
              <Form
                form={formEditAddress}
                onFinish={handleFinishEditAddress}
                className="request-ship-2-buyer__modal-edit-address-form"
                layout="vertical"
              >
                {Object.keys(modalEditAddress.data).map((key: keyof typeof modalEditAddress.data) => (
                  <Form.Item
                    name={key}
                    label={labelFormEdit[key]}
                    rules={
                      key === 'Phone'
                        ? [
                            {
                              message: '',
                              required: true,
                              transform: (value) => (value ? value.replace(/[^\d]/g, '') : value),
                              max: 100,
                            },
                          ]
                        : key === 'Street2'
                          ? undefined
                          : [
                              {
                                required: true,
                                transform: (value) => (value ? value.trim() : value),
                                message: '',
                                max: 100,
                              },
                            ]
                    }
                  >
                    {key === 'Country' ? (
                      <CustomSelect
                        className="item-country"
                        suffixIcon={<SvgArrowDownSelect />}
                        optionLabelProp="label"
                        options={listCountries}
                        placeholder="選択してください"
                        showSearch
                        optionFilterProp="label"
                      />
                    ) : (
                      <CustomInput
                        autoComplete="off"
                        type={key === 'Phone' ? 'number' : 'text'}
                        onKeyDown={(e) => {
                          if (key !== 'Phone') return;
                          if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') e.preventDefault();
                        }}
                      />
                    )}
                  </Form.Item>
                ))}
              </Form>
            }
            isOpen={modalEditAddress.isOpen}
            onCancel={() => setModalEditAddress({ ...modalEditAddress, isOpen: false })}
            modalProps={{
              closable: true,
              title: 'バイヤー情報編集',
              className: 'request-ship-2-buyer__modal-edit-address',
              footer: (
                <>
                  <ButtonCancel
                    value="キャンセル"
                    onClick={() => setModalEditAddress({ ...modalEditAddress, isOpen: false })}
                  />
                  <ButtonAgreeWhite
                    value="元に戻す"
                    onClick={() => formEditAddress.setFieldsValue(orderEbay?.data?.ShippingAddress)}
                  />
                  <ButtonAgree value="保存" onClick={() => formEditAddress.submit()} />
                </>
              ),
            }}
          />
        </>
      )}
      {(loadingGetOne || loadingGetOneOrder || loadingCarrier || loadingCreateShip2Buyer) && (
        <div className="loading">
          <Spin />
        </div>
      )}
    </div>
  );
}
