import { Image, Radio, Spin } from 'antd';
import { TDataRequest } from '../request';
import CustomCheckbox from '../../../../components/checkbox';
import { useMutation } from '@tanstack/react-query';
import {
  CreateShipmentDto,
  CreateShipmentResponse,
  ParcelsDtoDistanceUnitEnum,
  ParcelsDtoMassUnitEnum,
  Rate,
  ShippingAddress,
  StorageItem,
} from '../../../../apis/client-axios';
import { shippoApi } from '../../../../apis';
import { useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';

type TProps = {
  dataRequest: TDataRequest;
  setDataRequest: React.Dispatch<React.SetStateAction<TDataRequest>>;
  itemDetail: AxiosResponse<StorageItem, any>;
  modalEditAddress: {
    isOpen: boolean;
    isChange: boolean;
    data: Omit<ShippingAddress, 'AddressID' | 'AddressOwner' | 'CountryName'>;
  };
  carrier: AxiosResponse<CreateShipmentResponse, any>;
  loadingCarrier: boolean;
};

const Carrier = ({ dataRequest, setDataRequest, itemDetail, modalEditAddress, carrier, loadingCarrier }: TProps) => {
  const [listCarrier, setListCarrier] = useState(carrier?.data?.rates);
  const getBodyShipment = () => {
    let body: CreateShipmentDto = {
      ebayAccountId: itemDetail?.data?.ebayReturns?.accountEbayId,
      address_to: {
        name: modalEditAddress.data.Name,
        street1: modalEditAddress.data.Street1 || modalEditAddress.data.Street2,
        street2: modalEditAddress.data.Street2 ?? '',
        city: modalEditAddress.data.CityName,
        state: modalEditAddress.data.StateOrProvince,
        zip: modalEditAddress.data.PostalCode,
        country: modalEditAddress.data.Country,
        phone: modalEditAddress.data.Phone,
      },
      parcels: [
        {
          distance_unit: ParcelsDtoDistanceUnitEnum.Cm,
          height: String(itemDetail?.data?.storageHeight),
          length: String(itemDetail?.data?.storageLength),
          mass_unit: ParcelsDtoMassUnitEnum.Kg,
          weight: String(itemDetail?.data?.storageWeight),
          width: String(itemDetail?.data?.storageWidth),
          metadata: '',
        },
      ],
      customs_declaration: {
        items: [
          {
            quantity: 1,
            description: itemDetail?.data?.itemEditName || '',
            mass_unit: 'kg',
            origin_country: 'US',
            hs_code: dataRequest.HSCode,
            sku_code: itemDetail?.data?.itemSku,
            net_weight: String(itemDetail?.data?.storageWeight),
            value_amount: String(dataRequest.itemPrice),
            value_currency: 'USD',
          },
        ],
      },
    };

    return body;
  };
  const bodyShipment = useMemo(() => {
    return getBodyShipment();
  }, [dataRequest.HSCode, dataRequest.itemPrice, itemDetail, modalEditAddress.data]);

  const { mutate: getCarrierChooseUps, isPending: loadingGetCarrier } = useMutation({
    mutationKey: ['chooseCarrier'],
    mutationFn: (data: CreateShipmentDto) => shippoApi.shippoApiControllerCreateShipment(data),
  });

  return (
    <div className="box">
      <div className="title">
        <h3>配送会社</h3>
      </div>
      <div className="list-info content-partitions">
        {!loadingCarrier ? (
          <Radio.Group value={dataRequest.carrier}>
            <div className="carrier">
              {listCarrier ?? carrier?.data?.rates
                ? Object.values(listCarrier ?? carrier?.data?.rates)
                    .filter((v: Rate) => (dataRequest.confirm ? v?.provider === dataRequest.carrier : v))
                    .map((item, index) => (
                      <label
                        className={`carrier-item ${dataRequest.carrier === item.provider && 'active'} ${dataRequest.confirm && 'disabled'}`}
                        key={index}
                      >
                        <Radio
                          disabled={dataRequest.confirm}
                          value={item.provider}
                          id={item.provider}
                          onChange={() => {
                            if (item.provider == 'UPS' && bodyShipment.address_to.country != 'US') {
                              getCarrierChooseUps(
                                {
                                  ...bodyShipment,
                                  customs_declaration: {
                                    ...bodyShipment.customs_declaration,
                                    commercial_invoice: true,
                                  },
                                },
                                {
                                  onSuccess: (res) => {
                                    setDataRequest({
                                      ...dataRequest,
                                      carrier: item.provider,
                                      carrierPrice: +item.amount,
                                      shippingPlan: item.servicelevel['displayName'] ?? item.servicelevel['name'],
                                      shippingRate: res?.data?.rates[item.provider]?.objectId ?? '',
                                    });
                                  },
                                },
                              );
                            } else {
                              setDataRequest({
                                ...dataRequest,
                                carrier: item.provider,
                                carrierPrice: +item.amount,
                                shippingPlan: item.servicelevel.displayName ?? item.servicelevel.name,
                                shippingRate: item.objectId,
                              });
                            }
                          }}
                        />
                        <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                          <Image
                            preview={false}
                            width={64}
                            src={`/assets/icons/carrier/${item?.provider?.toLowerCase()}.png`}
                          />
                          <div>
                            <span className="name">{item?.provider}</span>
                            <div>
                              <span className="price">${item?.amount}</span>
                            </div>
                            <div className="font-base color-sub-title font-weight-500 font-size-12 m-t-4 text-overflow-130">
                              {item?.servicelevel?.displayName ?? item?.servicelevel?.name}
                            </div>
                            <div className="font-base color-cancel font-weight-500 font-size-10 m-t-2">
                              お届け日数：{item?.estimatedDays}
                            </div>
                          </div>
                        </div>
                        {/* {item.provider === 'USPS' && (
                          <div
                            className="carrier-item-information"
                            onClick={() => setDataRequest({ ...dataRequest, openModalInformation: true })}
                          >
                            <SvgInfo />
                          </div>
                        )} */}
                      </label>
                    ))
                : null}
            </div>
          </Radio.Group>
        ) : null}
        {(dataRequest.confirm && dataRequest.isInsurance) || !dataRequest.confirm ? (
          <>
            <div className="insurance">
              <div className="insurance_wp">
                {!dataRequest.confirm ? (
                  <CustomCheckbox
                    className="insurance_item_checkbox"
                    checked={dataRequest.isInsurance}
                    onChange={({ target: { checked } }) => {
                      setDataRequest({
                        ...dataRequest,
                        isInsurance: checked,
                        carrier: '',
                        carrierPrice: 0,
                        shippingPlan: '',
                        shippingRate: '',
                      });

                      const newBodyShipment = {
                        ...bodyShipment,
                        extra: checked
                          ? {
                              insurance: {
                                amount: dataRequest.orderItemPrice.toString(),
                                currency: 'USD',
                              },
                            }
                          : undefined,
                      };
                      getCarrierChooseUps(newBodyShipment, {
                        onSuccess: (res) => {
                          if (res?.data?.rates) {
                            setListCarrier(res?.data?.rates);
                          } else {
                            setListCarrier(carrier?.data?.rates);
                          }
                        },
                        onError: (err) => {},
                      });
                    }}
                    disabled={dataRequest.confirm}
                  />
                ) : (
                  <div></div>
                )}
                <label>保険料</label>
                <span className="colon">:</span>
                <div className="insurance_item_price">${dataRequest.insurance}</div>
              </div>
            </div>
          </>
        ) : (
          <div className="content-partitions"></div>
        )}
      </div>
      {loadingGetCarrier && (
        <div className="loading">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Carrier;
