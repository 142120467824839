import { AxiosResponse } from 'axios';
import RowDetail from '../../../../components/InfoRow/NewRowDetail';
import { OrderXML } from '../../../../apis/client-axios';
import { useLocation } from 'react-router-dom';
import { TIME_FORMAT } from '../../../../util/constants';
import moment from 'moment';
import { getLinkOrderEbayNavigate } from '../../../../util/function-util';
type TProps = {
  orderEbay: AxiosResponse<OrderXML, any>;
};

const ShipInfo = ({ orderEbay }: TProps) => {
  const { state } = useLocation();
  const Transaction = orderEbay?.data?.TransactionArray?.Transaction as any; // type
  const item = Array.isArray(Transaction)
    ? Transaction?.find((v) => v.OrderLineItemID === state?.lineItemID)
    : Transaction;
  return (
    <div className="box shipping-info">
      <div className="title">
        <h3>オーダー情報</h3>
      </div>
      <div className="list-info">
        <RowDetail
          label="オーダーID"
          value={orderEbay?.data?.OrderID}
          underline
          isLink
          href={getLinkOrderEbayNavigate(orderEbay?.data?.OrderID)}
        />
        <RowDetail label="セラーSKU" value={item?.Variation?.SKU || item?.Item?.SKU} />
        <RowDetail
          label="注文日"
          value={state?.shipByDate ? moment(state?.shipByDate).format(TIME_FORMAT.DATE('/')) : ''}
        />
        <RowDetail
          label="発送期限"
          value={
            item?.ShippingServiceSelected?.ShippingPackageInfo?.EstimatedDeliveryTimeMax
              ? moment(item?.ShippingServiceSelected?.ShippingPackageInfo?.EstimatedDeliveryTimeMax).format(
                  TIME_FORMAT.DATE('/'),
                )
              : ''
          }
        />
      </div>
    </div>
  );
};

export default ShipInfo;
