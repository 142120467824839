import { Menu, MenuProps } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../../constants/router';
import { RootState } from '../../../store';
import { SvgSettingActive, SvgSettingInActive } from '../@svg/SvgSetting';

type MenuItem = Required<MenuProps>['items'][number];
const pageActive = [
  USER_ROUTE_PATH.PROFILE,
  USER_ROUTE_PATH.EBAY_MANAGEMENT,
  USER_ROUTE_PATH.MEMBER_MANAGEMENT,
  USER_ROUTE_PATH.SETTING_NOTIFICATION,
  USER_ROUTE_PATH.FEDEX,
  USER_ROUTE_PATH.PLAN_SETTING,
  USER_ROUTE_PATH.CARD,
  USER_ROUTE_PATH.PAYMENT_LIST_HISTORY,
  USER_ROUTE_PATH.PAYMENT_LIST_HISTORY_DETAIL,
  USER_ROUTE_PATH.QUIT_MEMBERSHIP,
  USER_ROUTE_PATH.SUPPORT,
] as const;
const Setting = () => {
  const navigate = useNavigate();
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const userItem: MenuItem[] = [
    {
      key: '0',
      onClick: () => setIsOpen(true),
      label: (
        <div className="header-right__item setting" onClick={() => setIsOpen(true)}>
          <SvgSettingActive />
        </div>
      ),
      children: [
        {
          key: '1',
          label: 'ebayアカウント管理',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.EBAY_MANAGEMENT, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.EBAY_MANAGEMENT);
            }
          },
        },
        {
          key: '2',
          label: 'メンバー管理',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.MEMBER_MANAGEMENT, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.MEMBER_MANAGEMENT);
            }
          },
        },
        {
          key: '3',
          label: '通知設定',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.SETTING_NOTIFICATION, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.NOTIFY_SETTING);
            }
          },
        },
        {
          key: '4',
          label: 'JP返送ラベル発行設定',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.FEDEX, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.FEDEX);
            }
          },
        },
        {
          key: '5',
          label: '自動メッセージ',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.AUTO_MESSAGE, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.AUTO_MESSAGE);
            }
          },
        },
      ],
    },
  ];
  const subUserItem: MenuItem[] = [
    {
      key: '0',
      onClick: () => setIsOpen(true),
      label: (
        <div className="header-right__item setting" onClick={() => setIsOpen(true)}>
          <SvgSettingActive />
        </div>
      ),
      children: [
        {
          key: '1',
          label: 'ebayアカウント管理',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.EBAY_MANAGEMENT, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.EBAY_MANAGEMENT);
            }
          },
        },
        {
          key: '2',
          label: 'メンバー管理',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.MEMBER_MANAGEMENT, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.MEMBER_MANAGEMENT);
            }
          },
        },
        {
          key: '3',
          label: '通知設定',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.SETTING_NOTIFICATION, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.NOTIFY_SETTING);
            }
          },
        },
        {
          key: '5',
          label: '自動メッセージ',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.AUTO_MESSAGE, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.AUTO_MESSAGE);
            }
          },
        },
      ],
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.menu-setting')
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div
      className={`cursor-pointer menu-profile menu-setting ${pageActive.includes(location.pathname) ? 'active' : ''}`}
      ref={menuRef}
    >
      {isOpen ? (
        <Menu
          mode="inline"
          defaultOpenKeys={['0']}
          triggerSubMenuAction="hover"
          onSelect={() => setIsOpen(false)}
          onOpenChange={(value: string[]) => setIsOpen(value.includes('0'))}
          items={authUser?.parentId ? subUserItem : userItem}
        />
      ) : (
        <div className="header-right__item setting" onClick={() => setIsOpen(true)}>
          <SvgSettingInActive />
        </div>
      )}
    </div>
  );
};

export default memo(Setting);
