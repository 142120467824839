import { Avatar } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Helper } from '../../../../util/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { StorageItem } from '../../../../apis/client-axios';
import { AxiosResponse } from 'axios';

type TProps = {
  itemDetail: AxiosResponse<StorageItem, any>;
};

const MemoInfo = ({ itemDetail }: TProps) => {
  const { authUser } = useSelector((state: RootState) => state.auth);

  return (
    <div className="box memo">
      <div className="title">
        <h3>MEMO</h3>
      </div>
      <div className="memo__wrap">
        <div className="memo__content">
          <div className="avt">
            <img src="/assets/baypackavt.png" alt="" />
          </div>
          <TextArea rows={3} disabled value={itemDetail?.data?.staffComment} />
        </div>
        <div className="memo__content">
          <div className="avt">
            <Avatar size={40} src={Helper.getSourceFile(authUser?.avatar?.source)} alt="alt.." />
          </div>
          <div className="edit">
            <div className="custom-textarea-container">
              <TextArea rows={3} value={itemDetail?.data?.userNote} className="custom-textarea" disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemoInfo;
