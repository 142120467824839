import { Avatar, Menu, MenuProps } from 'antd';
import { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Asset } from '../../../apis/client-axios';
import { MEMBER_PERMISSIONS, USER_ROUTE_NAME, USER_ROUTE_PATH } from '../../../constants/router';
import { RootState, useAppDispatch } from '../../../store';
import { logout } from '../../../store/authSlice';
import { Helper } from '../../../util/helper';
import { socketConfig } from '../../../webSocket/webSocket.config';
import './style.scss';
import { MainAppContext } from '../../App/MainApp';
import { disableMenu } from '../../../util/menu';
import { ArrowDownIcon } from '../../../routes/customer/itemMangement/newItemDetail/@svg/ArrowDownIcon/ArrowDownIcon';

type MenuItem = Required<MenuProps>['items'][number];
const pageActive = [
  USER_ROUTE_PATH.PROFILE,
  USER_ROUTE_PATH.EBAY_MANAGEMENT,
  USER_ROUTE_PATH.MEMBER_MANAGEMENT,
  USER_ROUTE_PATH.SETTING_NOTIFICATION,
  USER_ROUTE_PATH.FEDEX,
  USER_ROUTE_PATH.PLAN_SETTING,
  USER_ROUTE_PATH.CARD,
  USER_ROUTE_PATH.PAYMENT_LIST_HISTORY,
  USER_ROUTE_PATH.PAYMENT_LIST_HISTORY_DETAIL,
  USER_ROUTE_PATH.QUIT_MEMBERSHIP,
  USER_ROUTE_PATH.SUPPORT,
] as const;
const AccountInfo = (props: { infoDropdownItems?: MenuProps['items'] }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [avatar, setAvatar] = useState<Asset | undefined>(undefined);
  const { isOpen, setIsOpen } = useContext(MainAppContext);
  const location = useLocation();
  useEffect(() => {
    if (authUser) {
      setAvatar(authUser?.avatar);
    }
  }, [authUser]);

  const userItem: MenuItem[] = [
    {
      key: '0',
      onClick: () => setIsOpen(true),
      label: (
        <Avatar
          className="my-auto custom-avatar width-40 height-40"
          src={Helper.getSourceFile(avatar?.source || avatar?.preview)}
        />
      ),
      children: [
        {
          key: '1',
          label: 'プロフィール',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.PROFILE, '_blank');
            } else {
              navigate(USER_ROUTE_PATH.PROFILE);
            }
          },
        },
        {
          key: '2',
          label: 'ご利用料金確認',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.PAYMENT_LIST_HISTORY, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.PAYMENT_LIST_HISTORY);
            }
          },
        },
        {
          key: '3',
          label: '料金プラン・お支払い管理',
          children: [
            {
              key: '2-1',
              label: '料金プラン変更',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PLAN_SETTING, '_blank');
                } else {
                  navigate(USER_ROUTE_PATH.PLAN_SETTING);
                }
              },
            },
            {
              key: '2-2',
              label: 'お支払い方法',
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.CARD, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.EDIT_CARD);
                }
              },
            },
          ],
        },
        {
          key: '4',
          label: 'サポート',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.SUPPORT, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.SUPPORT);
            }
          },
        },
        {
          key: '5',
          label: <span className="logout">ログアウト</span>,
          onClick: () => {
            socketConfig.disconnect();
            dispatch(logout());
            localStorage.removeItem('token');
            localStorage.removeItem('authUser');
          },
        },
      ],
    },
  ];
  const subUserItem: MenuItem[] = [
    {
      key: '0',
      onClick: () => setIsOpen(true),
      label: (
        <Avatar
          className="my-auto custom-avatar width-40 height-40"
          src={Helper.getSourceFile(avatar?.source || avatar?.preview)}
        />
      ),
      children: [
        {
          key: '1',
          label: 'プロフィール',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.PROFILE, '_blank');
            } else {
              navigate(USER_ROUTE_PATH.PROFILE);
            }
          },
        },
        {
          key: '2',
          label: 'ご利用料金確認',
          onClick: (e) => {
            if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
              window.open(USER_ROUTE_PATH.PAYMENT_LIST_HISTORY, '_blank');
            } else {
              navigate(USER_ROUTE_NAME.PAYMENT_LIST_HISTORY);
            }
          },
        },
        {
          key: '3',
          label: '料金プラン・お支払い管理',
          children: [
            {
              key: '3-1',
              label: '料金プラン変更',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.PaymentManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.PLAN_SETTING, '_blank');
                } else {
                  navigate(USER_ROUTE_PATH.PLAN_SETTING);
                }
              },
            },
            {
              key: '3-2',
              label: 'お支払い方法',
              disabled: disableMenu(authUser?.permissions, MEMBER_PERMISSIONS.PaymentManagement),
              onClick: (e) => {
                if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
                  window.open(USER_ROUTE_PATH.CARD, '_blank');
                } else {
                  navigate(USER_ROUTE_NAME.EDIT_CARD);
                }
              },
            },
          ],
        },
        {
          key: '5',
          label: <span className="logout">ログアウト</span>,
          onClick: () => {
            socketConfig.disconnect();
            dispatch(logout());
            localStorage.removeItem('token');
            localStorage.removeItem('authUser');
          },
        },
      ],
    },
  ];

  return (
    <div className={`cursor-pointer menu-profile ${pageActive.includes(location.pathname) ? 'active' : ''}`}>
      {isOpen ? (
        <Menu
          mode="inline"
          defaultOpenKeys={['0']}
          triggerSubMenuAction="hover"
          onSelect={() => setIsOpen(false)}
          onOpenChange={(value: string[]) => setIsOpen(value.includes('0'))}
          items={authUser?.parentId ? subUserItem : userItem}
          expandIcon={({ isOpen }) => (
            <div className={`menu-arrow-icon ${isOpen ? 'rotate-180' : ''}`}>
              <ArrowDownIcon />
            </div>
          )}
        />
      ) : (
        <Avatar
          onClick={() => setIsOpen(true)}
          className="my-auto custom-avatar width-40 height-40"
          src={Helper.getSourceFile(avatar?.source || avatar?.preview)}
        />
      )}
    </div>
  );
};

export default memo(AccountInfo);
