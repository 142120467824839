import { AxiosResponse } from 'axios';
import { StorageItem } from '../../../../../apis/client-axios';
import './SizeInfo.scss';

type TProps = {
  itemDetail?: AxiosResponse<StorageItem, any>;
  dataShipping?: any;
  title: string;
};
const SizeInfo = ({ itemDetail, dataShipping, title }: TProps) => {
  return (
    <div className="box box-size__info">
      <div className="title">
        <h3>{title}</h3>
      </div>
      <div className="list-info size-info">
        <div className="size-info__item">
          <span className="size-info__item-label">梱包サイズ（cm）</span>
          <div className="size-info__item-value">
            <div className="detail">
              <span>縦</span>
              <div className="number">
                <span>{itemDetail?.data?.storageLength || dataShipping.reportLength}</span>
              </div>
            </div>
            <div className="detail">
              <span>横</span>
              <div className="number">
                <span>{itemDetail?.data?.storageWidth || dataShipping.reportWidth}</span>
              </div>
            </div>
            <div className="detail">
              <span>高さ</span>
              <div className="number">
                <span>{itemDetail?.data?.storageHeight || dataShipping.reportHeight}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="size-info__item">
          <span className="size-info__item-label">重量（kg）</span>
          <div className="size-info__item-value">
            <div className="detail detail-weight">
              <div className="number">
                <span>{itemDetail?.data?.storageWeight || dataShipping.reportWeight}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizeInfo;
