import { AxiosResponse } from 'axios';
import { OrderXML, StorageItem } from '../../../../apis/client-axios';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';
import { TDataRequest } from '../request';
import CustomSelect from '../../../../components/select/CustomSelect';
import { SvgArrowDownSelect } from '../../../../components/@svg/SvgArrowDownSelect';
import { CheckIcon } from '../@svg/MagnifyingGlassIcon/CheckIcon/CheckIcon';
import { useEffect, useState } from 'react';

type TProps = {
  orderEbay: AxiosResponse<OrderXML, any>;
  dataRequest: TDataRequest;
  setDataRequest: React.Dispatch<React.SetStateAction<TDataRequest>>;
  itemDetail: AxiosResponse<StorageItem, any>;
};

enum BatteryType {
  NO_BATTERY = 'バッテリーなし',
  BUILT_IN_LITHIUM_METAL = '機器内に組み込まれているリチウムメタル電池（UN3091、PI970セクションII）',
  PACKAGED_LITHIUM_METAL = '機器と共に包装しているリチウムメタル電池（UN3091、PI969セクションII）',
  BUILT_IN_LITHIUM_ION = '機器内に組み込まれているリチウムイオン電池（UN3481、PI967セクションII）',
  PACKAGED_LITHIUM_ION = '機器と共に包装しているリチウムイオン電池（UN3481、PI966セクションII）',
}

const SizePackage = ({ orderEbay, dataRequest, setDataRequest, itemDetail }: TProps) => {
  const { state } = useLocation();
  const IOSS = state['IOSS'];
  const [localItemPrice, setLocalItemPrice] = useState('');
  const [localHSCode, setLocalHSCode] = useState('');

  const mapOption: any = [
    {
      value: BatteryType.NO_BATTERY,
      label: (
        <div className="option-pin">
          <span className="text">{BatteryType.NO_BATTERY}</span>
          {dataRequest?.battery.includes(BatteryType.NO_BATTERY) && <CheckIcon />}
        </div>
      ),
    },
    {
      value: BatteryType.BUILT_IN_LITHIUM_METAL,
      label: (
        <div className="option-pin">
          <span className="text">{BatteryType.BUILT_IN_LITHIUM_METAL}</span>
          {dataRequest?.battery.includes(BatteryType.BUILT_IN_LITHIUM_METAL) && <CheckIcon />}
        </div>
      ),
    },
    {
      value: BatteryType.PACKAGED_LITHIUM_METAL,
      label: (
        <div className="option-pin">
          <span className="text">{BatteryType.PACKAGED_LITHIUM_METAL}</span>
          {dataRequest?.battery.includes(BatteryType.PACKAGED_LITHIUM_METAL) && <CheckIcon />}
        </div>
      ),
    },
    {
      value: BatteryType.BUILT_IN_LITHIUM_ION,
      label: (
        <div className="option-pin">
          <span className="text">{BatteryType.BUILT_IN_LITHIUM_ION}</span>
          {dataRequest?.battery.includes(BatteryType.BUILT_IN_LITHIUM_ION) && <CheckIcon />}
        </div>
      ),
    },
    {
      value: BatteryType.PACKAGED_LITHIUM_ION,
      label: (
        <div className="option-pin">
          <span className="text">{BatteryType.PACKAGED_LITHIUM_ION}</span>
          {dataRequest?.battery.includes(BatteryType.PACKAGED_LITHIUM_ION) && <CheckIcon />}
        </div>
      ),
    },
  ];

  const handleGetShipment = debounce((value, inputName: string) => {
    if (value === '') {
      return;
    }
    if (inputName == 'HSCode') {
      if (value.length > 255) {
        setDataRequest({ ...dataRequest, errorInputHScode: true });
      } else {
        setDataRequest({
          ...dataRequest,
          carrier: '',
          carrierPrice: 0,
          shippingPlan: '',
          shippingRate: '',
          errorInputHScode: false,
          HSCode: value,
          isInsurance: false,
        });
      }
    } else if (inputName == 'ItemPrice') {
      if (+value < 0 || +value > 999999999999) {
        setDataRequest({ ...dataRequest, errorInputItemPrice: true });
      } else {
        const itemPrice = +value || dataRequest.orderItemPrice;
        setDataRequest({
          ...dataRequest,
          carrier: '',
          carrierPrice: 0,
          shippingPlan: '',
          shippingRate: '',
          isInsurance: false,
          itemPrice: Number(value),
          errorInputItemPrice: false,
          insurance: +((1.25 * (itemPrice || dataRequest.orderItemPrice)) / 100).toFixed(2),
        });
      }
    }
  }, 1000);

  useEffect(() => {
    if (dataRequest?.itemPrice !== undefined && dataRequest?.itemPrice !== 0) {
      setLocalItemPrice(dataRequest.itemPrice.toString());
    }
    if (dataRequest?.HSCode) {
      setLocalHSCode(dataRequest.HSCode);
    }
  }, [dataRequest.itemPrice, dataRequest.HSCode]);

  useEffect(() => {
    if (!dataRequest.battery) {
      setDataRequest({ ...dataRequest, battery: mapOption[0].value });
    }
  }, []);

  return (
    <div className="box row-size">
      <div className="title">
        <h3>パッケージ情報</h3>
      </div>
      <div className="list-info size-pack">
        <div className="item">
          <p className="item__label">梱包サイズ（cm）</p>
          <div className="size">
            <div className="size__detail">
              <span>縦</span>
              <div className="number">
                <span>{itemDetail?.data?.storageLength}</span>
              </div>
            </div>
            <div className="size__detail">
              <span>横</span>
              <div className="number">
                <span>{itemDetail?.data?.storageWidth}</span>
              </div>
            </div>
            <div className="size__detail">
              <span>高さ</span>
              <div className="number">
                <span>{itemDetail?.data?.storageHeight}</span>
              </div>
            </div>
          </div>
          <div className="size weight">
            <div className="size__detail">
              <span>重量（kg）</span>
              <div className="number">
                <span>{itemDetail?.data?.storageWeight}</span>
              </div>
            </div>
          </div>
        </div>
        {orderEbay?.data?.ShippingAddress?.Country !== 'US' && (
          <div className="item">
            <p className="item__label">申告価格（$）</p>
            <div className="hs-price">
              <Input
                type="number"
                style={{ borderColor: dataRequest.errorInputItemPrice || +localItemPrice < 0 ? 'red' : '' }}
                value={localItemPrice}
                onChange={(e) => {
                  const value = e.target.value;
                  setLocalItemPrice(value);
                  handleGetShipment(value, 'ItemPrice');
                }}
              />
            </div>
            <div className="hs-code">
              <span>HSコード</span>
              <Input
                style={{ borderColor: dataRequest.errorInputHScode ? 'red' : '' }}
                value={localHSCode}
                onChange={(e) => {
                  const value = e.target.value;
                  setLocalHSCode(value);
                  handleGetShipment(value, 'HSCode');
                }}
              />
            </div>
            {IOSS && (
              <div className="hs-code">
                <span>IOSS</span>
                <div className="ioss">
                  <span>{IOSS}</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="item">
          <p className="item__label">リチウム電池</p>
          <div className="pin">
            <CustomSelect
              suffixIcon={<SvgArrowDownSelect />}
              options={mapOption}
              value={dataRequest?.battery}
              onChange={(e: string) => {
                setDataRequest({ ...dataRequest, battery: e });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizePackage;
