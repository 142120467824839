import { Image } from 'antd';
import ButtonAgree from '../../../../components/buttonCustom/ButtonAgree';
import ButtonCancel from '../../../../components/buttonCustom/ButtonCancel';
import { TDataRequest } from '../request';
import { CreateShipmentResponse, Rate } from '../../../../apis/client-axios';
import { AxiosResponse } from 'axios';
type TProps = {
  dataRequest: TDataRequest;
  handleClickBack: () => void;
  handleSubmit: () => void;
  carrier: AxiosResponse<CreateShipmentResponse, any>;
  orderByCountry?: string;
};
const Bill = ({ dataRequest, handleClickBack, handleSubmit, carrier, orderByCountry }: TProps) => {
  const isUS = orderByCountry === 'US';

  const baseDisabledConditions =
    !dataRequest.carrier || dataRequest.errorInputHScode || dataRequest.errorInputItemPrice;

  const disabled = isUS
    ? baseDisabledConditions
    : baseDisabledConditions || !dataRequest.HSCode || !dataRequest.itemPrice;

  return (
    <div className="bill">
      {!dataRequest.confirm ? (
        <div className="bill__info">
          <div className="bill__info__item">
            <span className="label">送料</span>
            <span className="value">${Number(dataRequest?.carrierPrice).toFixed(2)}</span>
          </div>
          <div className="bill__info__item">
            <span className="label">保険料</span>
            <span className="value">${(dataRequest.isInsurance ? dataRequest.insurance : 0).toFixed(2)}</span>
          </div>
        </div>
      ) : (
        <div className="bill__confirm">
          <div className="bill__confirm__item">
            <span className="title">送料</span>
            <div className="carrier">
              {Object.values(carrier?.data?.rates)
                .filter((v: Rate) => (dataRequest.confirm ? v?.provider === dataRequest.carrier : v))
                .map((item, index) => (
                  <div className="carrier-left">
                    <Image
                      preview={false}
                      width={64}
                      src={`/assets/icons/carrier/${item?.provider?.toLowerCase()}.png`}
                    />
                    <div className="carrier-left__info">
                      <span className="name">{item?.servicelevel?.displayName ?? item?.servicelevel?.name}</span>
                      <span className="day">About {item?.estimatedDays} days</span>
                    </div>
                  </div>
                ))}
              <div className="carrier-right">
                <span className="price">${Number(dataRequest?.carrierPrice).toFixed(2)}</span>
              </div>
            </div>
          </div>
          <div className="bill__confirm__item">
            <span className="title">オプション</span>
            <div className="insurance">
              <span className="label">保険</span>
              <span className="value">${(dataRequest.isInsurance ? dataRequest.insurance : 0).toFixed(2)}</span>
            </div>
          </div>
        </div>
      )}
      <div className="bill__total">
        <div className="bill__total__item">
          <span className="label">合計金額</span>
          <span className="value">
            ${(dataRequest.carrierPrice + (dataRequest.isInsurance ? dataRequest.insurance : 0)).toFixed(2)}
          </span>
        </div>
        <div className="request-ship-2-buyer-button-wrap">
          <ButtonCancel value="戻る" onClick={handleClickBack} />
          <ButtonAgree disabled={disabled} value={dataRequest.confirm ? '確定' : '確認'} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Bill;
